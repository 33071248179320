import React from "react";
import Parallax from "react-springy-parallax";
import Img from "gatsby-image";
import "../styles/index.scss";
import Animated from 'animated/lib/targets/react-dom'
import styled from "styled-components";
import Footer from "./footer";

const ImagePanel = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: 50% 50%;
`
const Content = styled.div`
  &{
    color: white;
    display: block;
    position: relative;
    height: 100%
    width: 100%;
    max-width: 1080px;
    margin-top: 50px;
    margin: 50px auto auto auto;
    white-space: pre-wrap;
  }
  & .title{
    font-family: averia;
    display: block;
    font-size: 5em;
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: right;
    
  }
  & .body{
    font-family: slabo;
    display: block;
    margin: auto;
    max-width: 80%;
    font-size: 1em;
    text-align: left;
  }
  & .events{
    font-family: slabo;
    display: block;
    margin: auto;
    max-width: 80%;
    font-size: 1.5em;
    text-align: center;
  }
  & iframe{
    display: block;
    margin: auto auto;
  }
`


class Home extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return(
      <Parallax ref="parallax" pages={4.8} id="main-container" effect={(animation, toValue) => Animated.timing(animation, { toValue, duration: 0 })}>
        <Parallax.Layer offset={0}>
          <ImagePanel fluid={this.props.data.fullband.childImageSharp.fluid} />
        </Parallax.Layer>
        <Parallax.Layer offset={0.9999} speed={1} className="panel">
          <Content>
            <span className="title">about  </span>
            <div className="body">
              <p>
                The Brightsiders are a 5-piece dining set originating from Ottawa, Canada, comprised of Liam McPherson (chair), Cam Izzard (chair), Ben Hooker (chair), Adam Robillard (table), and Carson Fang (chair).
                <br />
                <br />
                Formed in 2007, the alt-rock group has won multiple Grammies as well as an Oscar for Best Picture in 2015.
                <br />
                <br />
                Known for producing big hits such as "Baby Got Back", "Still D.R.E", and "Baby Shark", the group is now looking to invest in essential oils to expand their business venture.
                <br />
                <br />
                Plz <s>donate</s> buy stocks below.
              </p>
            </div>
          </Content>
        </Parallax.Layer>
        <Parallax.Layer offset={1.5} >
          <ImagePanel fluid={this.props.data.stage.childImageSharp.fluid} />
        </Parallax.Layer>
        <Parallax.Layer offset={2} speed={1} className="panel">
          <Content>
            <span className="title">shows  </span>
            <div className="events">
              <p>
                Governors Ball - Jun 6th -  <u>Tickets</u>  -  <u>RSVP</u>
                <br />
                <br />
                Escapade - Jun 20th -  <u>Tickets</u>  -  <u>RSVP</u>
                <br />
                <br />
                Blues Fest - Jul 9th -  <u>Tickets</u>  -  <u>RSVP</u>
                <br />
                <br />
                Osheaga - Jul 31st -  <u>Tickets</u>  -  <u>RSVP</u>
                <br />
                <br />
                Lollapalooza - Aug 1st -  <u>Tickets</u>  -  <u>RSVP</u>
                <br />
                <br />
                Reading and Leeds - Aug 29st -  <u>Tickets</u>  -  <u>RSVP</u>
              </p>
            </div>
          </Content>
        </Parallax.Layer>
        <Parallax.Layer offset={3} >
          <ImagePanel fluid={this.props.data.music.childImageSharp.fluid} />
        </Parallax.Layer>
        <Parallax.Layer offset={3.99} speed={1} className="panel">
          <Content>
            <span className="title">music  </span>
            <iframe src="https://open.spotify.com/embed/track/6habFhsOp2NvshLv26DqMb" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          </Content>
        </Parallax.Layer>
        <Footer />
      </Parallax>
    );
  }
}

export default Home