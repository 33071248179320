import React from "react";
import Home from "../components/home"
import { graphql } from "gatsby";


const Index = ({ data }) => {
  return (
    <Home data={data}/>
  );
}

export const query = graphql`
  query {

    fullband: file(relativePath: { eq: "fullband_3.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920, maxHeight: 1080) {
          aspectRatio
          sizes
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    stage: file(relativePath: { eq: "performance_2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920, maxHeight: 1080) {
          aspectRatio
          sizes
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    music: file(relativePath: { eq: "music_2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920, maxHeight: 1080) {
          aspectRatio
          sizes
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Index